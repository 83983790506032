import { Link } from 'gatsby';
import * as React from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import './index.css';

const hslColors: [number, number, number][] = [
  [55.5, 24.096, 32.549],
  [68.182, 12.941, 33.333],
  [212, 13.636, 43.137],
  [33, 8.8496, 44.314],
  [70, 6.7164, 47.451],
  [2.2222, 21.094, 49.804],
  [315, 6.4516, 51.373],
  [24.545, 27.273, 52.549],
  [17.938, 40.928, 53.529],
  [6, 8.6207, 54.51],

  [57.143, 9.2511, 55.49],
  [196.67, 7.9646, 55.686],
  [25.227, 41.905, 58.824],
  [156, 4.8077, 59.216],
  [336, 5.102, 61.569],
  [28.5, 23.256, 66.275],
  [50, 13.953, 66.275],
  [220, 14.286, 71.176],
  [21.29, 43.056, 71.765],
  [41.299, 53.846, 71.961],

  [37.5, 12.5, 74.902],
  [28.966, 24.786, 77.059],
  [42.857, 56.757, 78.235],
  [0, 0, 79.608],
  [26, 61.224, 80.784],
  [32.308, 68.421, 85.098],
  [42.857, 28.767, 85.686],
  [42.353, 80.952, 91.765],
  [300, 13.514, 92.745],
  [30, 41.176, 93.333],
];

const FaqsPage = () => {
  console.log(hslColors);
  return (
    <Layout>
      <Seo title="Gifts" />
      <div className="FaqContent">
        <h1 style={{ marginBottom: '2.5rem' }}>Gifts</h1>
        <p>
          Your company is all that we need, so we aren't asking or expecting any
          gifts from our guests. However, if you would like to treat us to
          something we have three suggestions below.
        </p>
        <p>
          Donations towards Honeymoon: our hope is to go to Iceland (the
          country, not the supermarket) around our 6th month anniversary. Any
          money given towards the honeymoon will be spent on sightseeing
          activities, food and drink and various experiences to treat ourselves
          to once we're there. If you would like to put money towards our
          honeymoon, you can send it to our joint account.
        </p>
        <p>
          Donations towards Home: This year we bought and moved into our first
          owned home. There are lots of changes we'd like to make and a lot of
          redecoration needed to make it our own. If you would like to put money
          towards our new home, you can send it to our joint account.
        </p>
        <p>
          If sending a gift to our joint account, please add a reference of
          "honeymoon" or "house" so we know what you would like us to spend it
          on. Thanks again.
        </p>
        <JointAccount />
        <p>
          Gifts: If you would prefer to give us a physical gift, we have created
          a gift registry that includes a selection of board games, a collection
          of spirits and our favourite sweet treats. If you would like to buy us
          something special, you can find the registry{' '}
          <Link
            to="https://www.amazon.co.uk/hz/wishlist/ls/3HUZZVE3MFH00"
            target="_blank"
          >
            here
          </Link>
          .
        </p>
        <h2>ANY MORE QUESTIONS?</h2>
        <p>
          If you have any questions please either text or call us via the
          details provided on your invitation.
        </p>
      </div>
    </Layout>
  );
};

export default FaqsPage;

const JointAccount = () => {
  return (
    <div className="paymentDetails">
      <div className="left">
        <span>Recipient name:</span>
        <span>Sort code:</span>
        <span>Account number:</span>
      </div>
      <div className="right">
        <span> Nicholas Fitton</span>
        <span> 04-00-04</span>
        <span> 98020171</span>
      </div>
    </div>
  );
};
